// router
import { Link } from 'react-router-dom';
// Ant
import { Result , Button } from 'antd';
// Cookies
import cookie from 'react-cookies';

/**
 * 未登录
 * @returns 
 */
function useDisp403(){
    if (!cookie.load("token")) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="对不起，你还未正确登录或登录信息已经过期，请重新录系统!"
                extra={[
                    <Link to="/login">
                        <Button type="primary">
                            重新登录
                        </Button>
                    </Link>
                ]}
            />                
        )
    };
}

/**
 * 登录显示样式
 * @returns 
 */
function useDisp(){
    if (!cookie.load("token")) {
        return {display:"none"};
    }else{
        return {};
    }
}

export { 
    useDisp403 ,     
    useDisp ,
}