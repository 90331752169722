// react
import { useState, useEffect } from "react";
// router
import { useNavigate } from "react-router";
// ant
import { Button, Form, Input} from 'antd';

// actions
import * as Actions from '../Controllers/loginAction';

const Login = () => {

    // vars
    const navigate = useNavigate();

    // styles
    const main = {
        display: "flex",
        flexDirection: "row",
        width:"100%",
        justifyContent: "center",
        alignItems: "center",
    };
    const form = {
        width:"400px",
        height:"300px",
        marginTop: window.innerHeight / 2 - 150
    };

    // effect
    useEffect(()=>{
        
    },[])

    // states
    const [username, setUsername] = useState(0);
    const [password, setPassword] = useState(0);

    // funtions
    const onFinish = async () => {
        if(await Actions.loginAct(username, password)==1){navigate('/regs');}
    };
    const handleUsername = (e) => { setUsername(e.target.value); };
    const handlePassword = (e) => { setPassword(e.target.value); };

    return (
        <>
            <div style={main}>
                <Form
                    name="basic"
                    style={form}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="账号"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '请输入用户名!',
                            },
                        ]}
                    >
                        <Input onChange={handleUsername} value={username} />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码!',
                            },
                        ]}
                    >
                        <Input.Password onChange={handlePassword} value={password} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "center" }}>
                        <Button type="primary" htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default Login;
