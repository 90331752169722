// axios
import Axios from "axios";

const login = (u,p) => {
    return Axios.post(
        'https://interface.cctrip.cc/api/user/login', {
        "u": u,
        "p": p,
    });
};

export { 
    login,
};