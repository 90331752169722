import {
    search_hito,
    update_hito,
    get_count
} from '../Services/eventService'

// 获取最新20条订单信息 
const search_twenty =  () => {
    return  search_hito({
        "event_id": 19,
        "group_id": 579
    });
};

// 
const UpdateOnoff =  (hito_id,update_arr) => {
    return  update_hito(
        19,
        hito_id,
        update_arr
    );
};

//
const UpdateHito_rank = (hito_id,update_arr) => {
    return update_hito(
        19,
        hito_id,
        update_arr
    );
}

//
const UpdateOthers = (hito_id,update_arr) => {
    return update_hito(
        19,
        hito_id,
        update_arr
    );
}

//
const GetCount = () => {
    return get_count();
};

export {
    search_twenty,
    UpdateOnoff,
    UpdateHito_rank,
    UpdateOthers,
    GetCount
};