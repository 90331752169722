// router
import { useNavigate } from 'react-router-dom';
// ant
import { Button, message, notification, Space } from 'antd';
// ant icons
import { ExclamationCircleFilled } from '@ant-design/icons';
// cookies
import cookie from 'react-cookies';

// Services
import{ 
    login
} from '../Services/loginService';

/**
 * 登录
 * @param {*} u 
 * @param {*} p 
 * @returns 
 */
const loginAct = async (u, p) => {
    const response = await login(u,p);
    let data = response.data.data;
    let msg = response.data.msg;
    let res = response.data.res;
    if (data == "") {
        //消息框，提示错误
        notification.open({
            btn: <Space><Button type="primary" danger size='small' onClick={() => { notification.destroy(); } }>关闭</Button></Space>,
            description: <div style={{ fontSize: "14px", color: "red" }}>请重新输入正确账号密码登录</div>,
            message: <div style={{ fontSize: "18px", color: "red" }}><ExclamationCircleFilled style={{ color: "red" }} />&nbsp;账号或密码错误!</div>,
            placement: 'top',
        });
        //登录不正确，清空原有登录信息
        cookie.remove("username");
        cookie.remove("token");
        return 0;
    } else {
        if(data == undefined){
            notification.open({
                btn: <Space><Button type="primary" danger size='small' onClick={() => { notification.destroy(); } }>关闭</Button></Space>,
                description: <div style={{ fontSize: "14px", color: "red" }}>请重新输入正确账号密码登录</div>,
                message: <div style={{ fontSize: "18px", color: "red" }}><ExclamationCircleFilled style={{ color: "red" }} />&nbsp;账号或密码错误!</div>,
                placement: 'top',
            });
            cookie.remove("username");
            cookie.remove("token");
            return 0;
        }
        //获取过期时间
        let expin = data.expin;
        let exp = new Date(expin * 1000);
        //获取token
        let token = data.token;
        //保存cookie
        cookie.save("username", u, { expires: exp });
        cookie.save("token", token, { expires: exp });
        //登录成功默认跳转页
        message.success("成功登录!");        
    }
    return 1;
}

export { 
    loginAct, //登录 -- (因为重名加Act加以区分)
};