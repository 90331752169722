// react
import { React, useState, useEffect, useRef } from 'react';

// ahook
import { useDocumentVisibility } from 'ahooks';

// ant
import {
    Breadcrumb, FloatButton, message, Form, Col, Row, Input, Button, Space, Select , Modal
} from 'antd';

// ant icon
import {
    HomeOutlined, QuestionOutlined, CommentOutlined, SearchOutlined, ClearOutlined,
    DeliveredProcedureOutlined
} from '@ant-design/icons';

// Hooks
import {
    useDisp403,
    useDisp,
} from '../Hooks/loginHooks';  

// Components
import  RegsTable  from '../Components/regsTable';

// Actions
import * as Actions from '../Controllers/regsAction';

const Hotel = () => {
    
    // ref
    const tableContainerRef = useRef();                                                     // 子组件引用
    const { TextArea } = Input;

    // state
    const [tableWidth, setTableWidth] = useState("100%");                                   // 表格宽度
    const [datasource, setDatasource] = useState([]);                                       // 注册列表数据
    const [counts,setCounts] = useState({});
    const [refresh,setRefresh] = useState(0);
    const [memo,setMemo] = useState("");
    const [isModalMemoOpen,setIsModalMemoOpen] = useState(false);
    const [selectedId,setSelectedId] = useState();
    const [isModalOthersOpen,setIsModalOthersOpen] = useState(false);
    //-----
    const [group_name,setGroup_name] = useState("");
    const [title_name,setTitle_name] = useState("");
    const [tel_1,setTel_1] = useState("");
    const [group_sales_name,setGroup_sales_name] = useState("");
    const [tel_2,setTel_2] = useState("");
    const [cr_val,setCr_val] = useState("");
    const [is_lodging,setIs_lodging] = useState("");

    // functions    
    const GetCount = (e) => {
        Actions.GetCount().then((rs)=>{
            let data = rs.data.data;
            setCounts(data);
        });
    };
    const onTwentyClick = (e) => {
        Actions.search_twenty().then((ds)=>{
            setDatasource(ds.data.data);
        });
    };
    const handleOnoff = async (id,onoff)=>{
        if(onoff=="1"){onoff="2";}else{onoff="1";}
        await Actions.UpdateOnoff(
            id,
            {"onoff":onoff}
        );
        GetCount();
        onTwentyClick();
    };
    const handleMemo = async (id) => { 
        setSelectedId(id);
        setIsModalMemoOpen(true);
    };
    const handleOthers = async (id,row) => {
        row = JSON.parse(row);
        console.log(row);
        setGroup_name(row.group_name);
        setTitle_name(row.title_name);
        setTel_1(row.tel_1);
        setGroup_sales_name(row.group_sales_name);
        setTel_2(row.tel_2);
        setCr_val(row.cr_val);
        setIs_lodging(row.is_lodging);
        setSelectedId(id);
        setIsModalOthersOpen(true);
    };
    const onFormMemoSubmit = async (e) => {
        let id = selectedId;
        let hito_rank = memo;
        await Actions.UpdateHito_rank(
            parseInt(id),
            {"hito_rank":hito_rank}
        );
        onTwentyClick();
        setIsModalMemoOpen(false);
    };
    const onFormOthersSubmit = async (e) => {
        let id = selectedId;
        await Actions.UpdateHito_rank(
            parseInt(id),
            {
                "group_name":group_name,
                "title_name":title_name,
                "tel_1":tel_1,
                "group_sales_name":group_sales_name,
                "tel_2":tel_2,
                "cr_val":cr_val,
                "is_lodging":is_lodging
            }
        );
        onTwentyClick();
        setIsModalOthersOpen(false);
    }

    // effect
    useEffect(()=>{
        GetCount();
        onTwentyClick();
    },[]);

    // style
    const styFormGutter = {padding:"20px 10px 0px 0px"};

    return (
        <>                
            {/* 未登录界面渲染 */}
            <>{useDisp403()}</>
            {/* useDisp登录显示样式键值对 */}
            <div style={{...useDisp()}}>                
                {/* 面包屑导航 */}
                <Breadcrumb
                    items={[
                        { title: <HomeOutlined /> },
                        { title: "参会注册" },
                    ]}
                />                    
                <RegsTable
                    dataSource={datasource}
                    counts={counts}
                    tableWidth={tableWidth}
                    ref={tableContainerRef}
                    handleOnoff={handleOnoff}
                    handleMemo={handleMemo}
                    handleOthers={handleOthers}
                />                            
            </div>
            
            <Modal
                title="修改备注"
                open={isModalMemoOpen}
                onOk={()=>setIsModalMemoOpen(false)} 
                onCancel={()=>setIsModalMemoOpen(false)}
                width={960}
                okText="关闭"
                cancelText="取消"
                footer={[
                    <Button
                      type="default"
                      onClick={()=>setIsModalMemoOpen(false)} 
                    >
                      关闭
                    </Button>,
                ]}
            >
                <Form
                    labelCol={{span: 3, offset: 0}}
                    layout="horizental"
                    onFinish={onFormMemoSubmit}
                    autoComplete="off"
                >
                    <Form.Item 
                        label="备注"                        
                    >
                        <TextArea rows={8} value={memo} onChange={(e)=>{setMemo(e.target.value)}} />                                
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 3,                                    
                        }}
                    >
                        <Space>
                            <Button 
                                type="primary" 
                                htmlType="submit"                                
                            >
                                确认修改
                            </Button>                                    
                        </Space>
                    </Form.Item>     
                </Form>            
            </Modal>

            <Modal
                title="修改其他"
                open={isModalOthersOpen}
                onOk={()=>setIsModalOthersOpen(false)} 
                onCancel={()=>setIsModalOthersOpen(false)}
                width={960}
                okText="关闭"
                cancelText="取消"
                footer={[
                    <Button
                      type="default"
                      onClick={()=>setIsModalOthersOpen(false)} 
                    >
                      关闭
                    </Button>,
                ]}
            >
                <Form
                    labelCol={{span: 3, offset: 0}}
                    layout="horizental"
                    onFinish={onFormOthersSubmit}
                    autoComplete="off"
                >
                    <Form.Item 
                        label="单位"                        
                    >
                        <Input value={group_name} onChange={(e)=>{setGroup_name(e.target.value)}}/>
                    </Form.Item>
                    <Form.Item 
                        label="职务"                        
                    >
                        <Input value={title_name} onChange={(e)=>{setTitle_name(e.target.value)}}/>
                    </Form.Item>
                    <Form.Item 
                        label="参会人电话"                        
                    >
                        <Input value={tel_1} onChange={(e)=>{setTel_1(e.target.value)}}/>
                    </Form.Item>
                    <Form.Item 
                        label="联络人"                        
                    >
                        <Input value={group_sales_name} onChange={(e)=>{setGroup_sales_name(e.target.value)}}/>
                    </Form.Item>
                    <Form.Item 
                        label="联络人电话"                        
                    >
                        <Input value={tel_2} onChange={(e)=>{setTel_2(e.target.value)}}/>
                    </Form.Item>
                    <Form.Item 
                        label="证件号"                        
                    >
                        <Input value={cr_val} onChange={(e)=>{setCr_val(e.target.value)}}/>
                    </Form.Item>
                    <Form.Item
                        label="是否住宿"
                    >
                        <Select
                            value={is_lodging}
                            style={{
                                width: 120,
                            }}
                            onChange={(value)=>{setIs_lodging(value)}}
                            options={[
                                {
                                    value: '1',
                                    label: '是',
                                },
                                {
                                    value: '0',
                                    label: '否',
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 3,                                    
                        }}
                    >
                        <Space>
                            <Button 
                                type="primary" 
                                htmlType="submit"                                
                            >
                                确认修改
                            </Button>                                    
                        </Space>
                    </Form.Item>     
                </Form>            
            </Modal>
        </>
    );

}

export default Hotel;