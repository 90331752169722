//核心
import { React } from 'react';
import ReactDOM from 'react-dom/client';

//路由
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Login from './Pages/login.js'; 
import Regs from './Pages/regs.js';                            

//布局，菜单
import { Layout, Menu ,Image} from 'antd';

//图标
import { UserOutlined , BankOutlined , TagOutlined , VerifiedOutlined, BarsOutlined} from '@ant-design/icons';

//cookie
import cookie from "react-cookies";

//ahooks
import { useTitle } from 'ahooks';


//css
import "./index.css";

//根组件
const App = () => {

    useTitle("广东旅游文化节");

    //顶部LOGO
    const logo = {
        fontSize: '20px',
        width: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    //顶部菜单
    const menu = {
        width: '100%',
    };
    //布局顶部
    const headerStyle = {
        //backgroundColor:"#001529",
        backgroundColor:"black",
        color: 'white',
        padding: 0,
        height:64,
        display: 'flex',
        alignItems: 'center',
    };
    //布局内容
    const contentStyle = {
        height: window.innerHeight - 64 - 32,
        color: '#333',
        backgroundColor: '#fff',
        padding:20,
    };

    //布局组件解构
    const { Header, Content } = Layout;

    //菜单结构数组
    const items = [
        {
            label: <Link to="/login">
                {(cookie.load("username")!=undefined)? cookie.load("username") : "登录系统"}
            </Link>,
            key: '/login',
            icon: <UserOutlined />,
        },
        {
            label: <Link to="/regs">注册列表</Link>,
            key: '/regs',
            icon: <BarsOutlined />,
        },
    ];

    //获取Link组件路由钩子传送的参数
    const Location = useLocation();
    const link_pathname = Location.state;

    //获取当前路径
    let pathname = window.location.pathname;
    //如果路径为根，菜单点亮key为/login
    if (pathname === "/") {
        pathname = "/login";
    }
    if (link_pathname != null) {
        pathname = link_pathname;
    }

    return (
        <>
            <Layout>
                {/* 布局顶部 */}
                <Header style={headerStyle}>
                    <div style={logo}>
                        <b>广东旅游文化节</b>
                    </div>
                    <Menu style={menu} selectedKeys={[pathname]} mode="horizontal" items={items} theme="dark" />
                </Header>

                {/* 布局内容 */}
                <Content style={contentStyle}>
                    {/* 路由渲染位置 */}
                    <Routes>
                        {/* 跟目录默认路由 */}
                        <Route path="/" element={<Login />} exact />
                        <Route path="/login" element={<Login />} /> 
                        <Route path="/regs" element={<Regs />} />     
                    </Routes>
                </Content>
            </Layout>
        </>
    );
}

//创建根节点
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //路由配置必须标签
    <Router>
        <App />
    </Router>
);