//核心
import { React , useState , useRef , useEffect , forwardRef , useImperativeHandle } from 'react';
// Antdesgin
import { Button, Input , Table , Space , Tag , Spin } from 'antd';
// ant图标
import {
    SearchOutlined, EditOutlined, DownloadOutlined
}
from '@ant-design/icons';
// 表格列搜索框需要
import Highlighter from 'react-highlight-words';

import {
    get_info,
} from '../Services/eventService'


const RegsTable = forwardRef((props,ref) => {

    // style
    const tableContainer={                             
        display:"block",
        //overflow:"scroll",
        //height:window.innerHeight-230,
        paddingRight:10,
        paddingBottom:10,
    };
    const table={                                     
        width:props.tableWidth,
        marginTop:"0px",
    };
    const styTable = {
        borderCollapse:"collapse",
        border:"1px solid #eeeeee",
    };
    const styTh = {
        borderCollapse:"collapse",
        border:"1px solid #eeeeee",
        backgroundColor:"#f8f8f8",
        padding:"3px",
    };
    const styTd = {
        borderCollapse:"collapse",
        border:"1px solid #eeeeee",
        padding:"3px",
    };
    const styCenter = { textAlign:"center"};
    const subTable = {
        border:"1px solid #eeeeee",
        borderCollapse:"collapse",
    }
    const stySubTdTitle = {
        border:"1px solid #eeeeee",
        borderCollapse:"collapse",
        backgroundColor:"#f8f8f8",
        padding:"5px",
    };
    const stySubTd = {
        border:"1px solid #eeeeee",
        borderCollapse:"collapse",
        padding:"5px",
    };    
    const labelRight = {borderRadius:"4px 0px 0px 4px",backgroundColor:"#e8e8e8",padding:"5px",borderRight:"3px solid #e0e0e0"};
    const labelLeft = {borderRadius:"0px 4px 4px 0px",backgroundColor:"#eeeeee",padding:"5px 20px 5px 20px"};
    const countTd = {padding:"10px"};
    const countTdFirst = {padding:"10px",paddingLeft:"0px"};
   

    // functions
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const handleOnoff = (e) => {
        let id = e.currentTarget.dataset["id"];
        let onoff = e.currentTarget.dataset["onoff"];
        props.handleOnoff(id,onoff);
    };
    const handleMemo = (e) => {
        let id = e.currentTarget.dataset.id;
        props.handleMemo(id);
    };
    const handleOthers = (e) => {
        let id = e.currentTarget.dataset.id;
        let row = e.currentTarget.dataset.row;
        props.handleOthers(id,row);
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`搜索关键字`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        清空
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        关闭
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const getColumns = () => {
        return [        
            {
                title: '是否上线',
                dataIndex: 'onoff',
                key: 'onoff',
                filters: [
                    { text: '待提交', value: 1 },
                    { text: '已提交', value: 2 },
                ],
                onFilter: (value, record) => record.onoff==value,
                render:(text,record,index)=>{
                    return (
                        <>  
                            <Space direction='vertical'>
                                {(text==1)?<Tag color="error">待提交</Tag>:<Tag color="success">已提交</Tag>
                                }
                                <Button 
                                    type='default' 
                                    size="small"
                                    data-id={record.id}
                                    data-onoff={record.onoff}
                                    onClick={handleOnoff}
                                >
                                    修改
                                </Button>
                            </Space>
                        </>
                    )
                }
            }, 
            {
                title: 'ID',
                dataIndex:'id',
                key: 'id'
            },
            {
                title: '参会人',
                dataIndex: 'hito_u',
                key: 'hito_u',
                ...getColumnSearchProps('hito_u'),
            },
            {
                title: '单位',
                dataIndex: 'group_name',
                key: 'group_name',
                ...getColumnSearchProps('group_name'),
            },
            {
                title: '职务',
                dataIndex: 'title_name',
                key: 'title_name',
            },
            {
                title: '参会人电话 ',
                dataIndex: 'tel_1',
                key: 'tel_1',
                ...getColumnSearchProps('tel_1'),
            },
            {
                title: '联络人',
                dataIndex: 'group_sales_name',
                key: 'group_sales_name',
                ...getColumnSearchProps('group_sales_name'),
            },
            {
                title: '联络人电话 ',
                dataIndex: 'tel_2',
                key: 'tel_2',
                ...getColumnSearchProps('tel_2'),
            },
            {
                title: '证件号 ',
                dataIndex: 'cr_val',
                key: 'cr_val',
                ...getColumnSearchProps('cr_val'),
            },
            {
                title: '是否住宿',
                dataIndex: 'is_lodging',
                key: 'is_lodging',
                filters: [
                    { text: '否', value: "0" },
                    { text: '是', value: "1" },
                ],
                onFilter: (value, record) => record.is_lodging==value,
                render:(text,record,index)=>{
                    return(
                        <>
                        {(text==0)?<>否</>:<>是</>}
                        </>
                    );
                }
            },
            {
                title: '24号(一)',
                dataIndex: 'sub_meeting',
                key: 'sub_meeting',
                filters: [
                    { text: '不参加', value: 0 },
                    { text: '参加', value: 1 },
                ],
                onFilter: (value, record) => record.sub_meeting==value,
                render:(text,record,index)=>{
                    return(
                        <>
                            {tableRef.meetings["sub_meeting"][text]}
                        </>
                    );    
                },                
            },            
            {
                title: '25号',
                dataIndex: 'sub_meeting3',
                key: 'sub_meeting3',
                filters: [
                    { text: '不参加', value: 0 },
                    { text: '参加', value: 1 },
                ],
                onFilter: (value, record) => record.sub_meeting3==value,
                render:(text,record,index)=>{
                    return(
                        <>
                            {tableRef.meetings["sub_meeting3"][text]}
                        </>
                    );    
                },
            },
            {
                title: '备注',
                dataIndex: 'hito_rank',
                key: 'hito_rank',
                filters: [
                    { text: '省内', value: '省内' },
                    { text: '省外', value: '省外' },
                ],
                onFilter: (value, record) => record.hito_rank==value,
            },
            {
                title: '编辑',
                render:(text,record,index)=>{
                    return(
                        <Space.Compact direction='vertical'>
                            <Button
                                type="primary"
                                size='small'
                                onClick={handleMemo}
                                data-id={record.id}
                            >
                                修改备注
                            </Button>
                            <Button
                                type="default"
                                size='small'
                                onClick={handleOthers}
                                data-id={record.id}
                                data-row={JSON.stringify(record)}
                            >
                                修改其他
                            </Button>
                        </Space.Compact>
                    )
                }
            }
        ]
    };   
    const GetInfo = async () => {
        setLoading(true);
        let rs = await get_info({
            "event_id": 19,
        });
        let meetings = rs.data.data;
        tableRef.meetings = meetings;
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };        

    // refs
    const searchInput = useRef(null);
    const tableContainerRef = useRef();
    // 向父组件传递ref对应可操作DOM元素的方法
    useImperativeHandle(ref, () => ({                                  
        scrollhorizental: (val) => {
            tableContainerRef.current.scrollLeft=val;
        }
    }));
    const tableRef = useRef(null);

    // states
    const [searchText,setSearchText] = useState();
    const [searchedColumn,setSearchedColumn] = useState();
    const [columns,setColumns] = useState(getColumns());    
    const [loading,setLoading] = useState();

    // effect
    useEffect(()=>{        
        GetInfo();                
    },[props.dataSource]);
    
    return (
        // tableContainerRef 定义可被父组件操作元素
        <Spin spinning={loading}>
        <div className="tableContainer" style={tableContainer} ref={tableContainerRef}>         
            <table style={{fontSize:"16px"}}>
                <tr>
                    <td style={countTdFirst}><b style={{...labelRight}}>注册人数</b><span style={{...labelLeft}}>{props.counts["total"]}</span></td>
                    <td style={countTd}><b style={{...labelRight}}>24号联席会议</b><span style={{...labelLeft}}>{props.counts["day24"]}</span></td>
                    <td style={countTd}><b style={{...labelRight}}>25号长隆</b><span style={{...labelLeft}}>{props.counts["day25_cl"]}</span></td>
                    <td style={countTd}><b style={{...labelRight}}>25号海岛</b><span style={{...labelLeft}}>{props.counts["day25_hd"]}</span></td>
                    <td style={countTd}><b style={{...labelRight}}>25号不参加</b><span style={{...labelLeft}}>{props.counts["day25_no"]}</span></td>
                    <td style={countTd}>
                        <Button
                            type='primary'
                            size='large'
                            icon={<DownloadOutlined />}
                            onClick={(e)=>{
                                window.open("https://op.cctrip.cc/lvwh_count.php?exp");
                            }}
                        >
                            报表
                        </Button>
                    </td>
                </tr>
            </table>        
            <div style={table}>
                <Table
                    bordered
                    size="large"
                    dataSource={props.dataSource}
                    columns={columns}
                    pagination={{
                        position: ['bottomLeft'],
                        defaultCurrent: 1,                        
                    }}
                />
            </div>
        </div>
        </Spin>
    );

});

export default RegsTable;