// axios
import Axios from "axios";
// cookie
import cookie from "react-cookies";

// 报名人信息
const search_hito = (args) => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer ' + cookie.load("token") }
    });
    return ajax.post(
        'https://interface.cctrip.cc/api/event/search_hito',
        {
            "event_id": args.event_id,  // 19
            "group_id": args.group_id,  // 579
            "page": 0,
            "pagesize": 10000,
            "keys": [
                "onoff",
                "id",
                "hito_u",
                "cr_val",
                "group_name",
                "title_name",
                'tel_1',
                "group_sales_name",
                'tel_2',
                "sub_meeting",
                "sub_meeting2",
                "sub_meeting3", 
                "is_lodging",
                "hito_rank",
                "memo",
            ]
        }
    );
};

//
const get_info = (args) => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer ' + cookie.load("token") }
    });
    return ajax.post(
        'https://interface.cctrip.cc/api/event/get_info',
        {
            "event_id": args.event_id,  // 19                       
        }
    );
}

//
const update_hito = (event_id,hito_id,update_arr) => {        
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer '+cookie.load("token") }
    });
    return ajax.post(
        'https://interface.cctrip.cc/api/event/update_hito', {
            "event_id" : event_id,
            "hito_id" : hito_id,            
            "update_arr" : update_arr
        }
    );
};

//
const get_count = () => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer '+cookie.load("token") }
    });
    return ajax.post(
        'https://interface.cctrip.cc/api/ly2023/get_count', {
            
        }
    );
}

export { 
    search_hito,
    get_info,
    update_hito,
    get_count
}